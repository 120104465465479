@use "../util" as *;

.listing {
  position: relative;
  border-radius: $radius-small;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  @include transition(box-shadow);
  &:hover,
  &:focus {
    box-shadow: 0 0 5px 2px $clr-secondary;
  }
  .img-wrapper {
    position: relative;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .updated-ago {
      position: absolute;
      left: 0;
      color: $clr-text-light;
      background-color: rgb(0, 0, 0, 0.6);
      border-top-left-radius: $radius-small;
      border-bottom-right-radius: $radius-small;
      padding: 0.5rem;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1.5rem;
    background-color: $clr-secondary;
    color: $clr-text-light;
    text-transform: capitalize;
    height: 100%;
    h3 {
      font-weight: 600;
      span {
        font-size: $text-xsmall;
        text-transform: lowercase;
      }
    }
    p {
      font-size: $text-small;
    }
  }

  &.is-loading {
    &:hover,
    &:focus {
      box-shadow: none;
    }
    .loader {
      display: block;
    }
    .img-wrapper,
    .info {
      @include transition(opacity);
      opacity: 0.4;
    }
  }
}
