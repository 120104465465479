@use "../util" as *;

.homes-main {
  gap: 1.5rem;
  width: clamp(270px, 90%, 1400px);
  position: relative;
  .no-results {
    h1 {
      font-size: $text-xlarge;
      text-align: center;
      margin-top: 1rem;
      color: $clr-secondary;
    }

    img {
      width: 100%;
      max-width: 600px;
    }
  }
}

.listings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  width: 100%;
  gap: 4rem;
}

@media (max-width: $bp-phone) {
  .listings {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}
