@use "../util" as *;

.cards-section {
  @include flexCenter(column);
  h2 {
    max-width: 35ch;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.75fr));
    gap: 4rem;
    justify-content: center;
    max-width: max-content;
    .card {
      @include flexCenter(column);
      gap: 1.5rem;
      background-color: $clr-secondary;
      border-radius: $radius-small;
      padding: 2rem;
      text-align: center;
      color: white;

      .img-wrapper {
        height: 100%;
        width: 100%;
        max-width: 175px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &:first-of-type .img-wrapper {
        max-width: 250px;
      }
      .card-btn {
        margin-bottom: 0;
        margin-top: auto;
        a {
          @include button($text-medium, $clr-accent-2);
        }
      }
    }
  }
}
