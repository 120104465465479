@use "../util" as *;

.article-section {
  @include flexCenter(column);
  h2 {
    max-width: 48ch;
  }
  .wrapper {
    width: 100%;
    display: flex;
    article {
      flex: 1;
      margin: auto 0;
      background-color: white;
      padding: 3rem 2rem;
      border-radius: $radius-small;
      box-shadow: 0 0 5px rgb(0, 0, 0, 0.25);
      position: relative;
      left: 2rem;
      z-index: 2;
      p {
        margin: 1.5rem 0;
        line-height: 140%;
      }
      a {
        color: $clr-accent-1;
        text-transform: capitalize;
        @include transition(opacity);
        font-weight: 500;
        &:hover {
          opacity: 0.7;
        }
      }
      .app-btns {
        display: flex;
        width: 100%;
        gap: 1rem;
        button a {
          height: 2.25rem;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .img-wrapper {
      flex: 2;
      position: relative;
      left: -2rem;
      border-radius: $radius-small;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &:nth-child(2) {
      .img-wrapper {
        order: 1;
        left: 2rem;
      }
      article {
        order: 2;
        left: -2rem;
      }
    }
  }
}

@media (max-width: $bp-tablet) {
  .article-section {
    .wrapper,
    .wrapper:nth-child(2) {
      flex-direction: column;
      gap: 1.5rem;
      article {
        left: 0;
        order: 2;
      }
      .img-wrapper {
        left: 0;
        order: 1;
      }
    }
  }
}
