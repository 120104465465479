@use "../util" as *;

.bcg-blur {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  bottom: 0;
  z-index: -10;
  visibility: hidden;
  opacity: 0;
  @include transition(opacity);
  &.show {
    z-index: 10;
    visibility: visible;
    opacity: 1;
  }
}

.single-listing {
  display: flex;
  width: clamp(270px, 90%, 1400px);
  background-color: white;
  gap: 2rem;
  padding: 2rem;
  border-radius: $radius-small;
  overflow: hidden;
  height: 95vh;
  position: relative;
  .info {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 95vh;
  }
  header.info-header {
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .logo {
        img {
          width: 1.5rem;
        }
        span {
          font-size: $heading-small !important;
          span {
            font-size: $text-xsmall !important;
          }
        }
      }
      .btns {
        display: flex;
        button {
          @include button($text-medium, white);
          color: $clr-text-dark;
          align-items: center;
          // heart
          &:nth-child(1) {
            .icon {
              padding-right: 0.25rem;
            }
          }
          // share
          &:nth-child(2) {
            .icon {
              font-size: $text-xlarge;
            }
          }
          // close
          &:nth-child(3) {
            padding-right: 0;
            .icon {
              font-size: $text-xlarge;
            }
          }
        }
        .icon {
          display: flex;
          font-size: $text-large;
        }
      }
    }
    .top-info {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .cost {
        font-size: $heading-medium;
        font-weight: 600;
        line-height: 100%;
        span {
          font-size: $text-small;
        }
      }
      .sqft {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      & > div:last-of-type {
        font-weight: 600;
        text-transform: capitalize;
        & p:first-of-type {
          margin-bottom: 0.5rem;
          font-size: $text-large;
        }
      }
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ul {
      list-style-type: none;
      &.show-all {
        max-height: max-content;
      }
      li {
        padding-left: 0.7rem;
        margin-bottom: 0.5rem;
        line-height: 150%;
        &::before {
          content: "- ";
          margin-left: -0.55rem;
        }
      }
    }
    h4 {
      margin-bottom: 0.5rem;
    }
  }
  .overview,
  .features {
    button {
      @include button($text-small, white);
      color: $clr-accent-1;
      padding-left: 0.25rem;
      padding-top: 0;
      font-weight: 600;
      align-items: center;
      max-width: max-content;
      .icon {
        display: flex;
        font-size: $text-xlarge !important;
      }
    }
  }

  .overview button {
    padding-left: 0;
  }

  .info-body {
    height: 39.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;
  }
  .overview,
  .contact {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    label {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .icon {
        font-size: $text-large;
        margin-right: 0.25rem;
      }
    }
  }

  .overview {
    p {
      line-height: 160%;
    }
    b {
      text-transform: capitalize;
    }
  }
  .contact {
    label {
      display: flex;
      flex-wrap: wrap;
      div {
        display: flex;
        align-items: center;
        .icon {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: $bp-desktop) {
  .single-listing {
    flex-direction: column;
    overflow-y: auto;
    .info {
      max-height: max-content;
      .top-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.5rem 2rem;
      }
    }
    .overview p {
      line-height: 180%;
      max-width: 75ch;
    }
    .info-body {
      height: max-content;
    }
  }
  .expand-pictures {
    // open pictures, hide other elements
    gap: 0 !important;
    overflow: hidden;
    .header.info-header,
    .top-info,
    .info-body,
    .seperator-line {
      display: none !important;
    }
  }
}

@media (max-width: $bp-phone) {
  .single-listing {
    padding: 0.75rem;
    padding-top: 1.75rem;

    header.info-header {
      .top-bar {
        padding: 0.25rem 0.75rem;
        .logo {
          img {
            width: 1rem;
          }
          & > span {
            font-size: 5vw !important;
            & > span {
              font-size: 2.5vw !important;
            }
          }
        }
        .btns button {
          font-size: 4vw;
          padding: 0.5rem 0.25rem;
          .icon {
            font-size: 5vw;
          }
        }
      }
      .top-info {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        .cost {
          font-size: 7vw;
        }
        .sqft {
          font-size: 4vw;
        }

        & > p,
        & > div p:last-of-type {
          font-size: 4vw;
        }
        & > div p:first-of-type {
          font-size: 5vw !important;
        }
      }
    }
    .overview {
      p {
        line-height: 140%;
      }
    }
    .info-body {
      label,
      ul,
      p,
      button {
        font-size: 4vw;
        .icon {
          font-size: 4vw;
        }
      }
      h3 {
        font-size: 5vw;
      }
      h4 {
        font-size: 4.5vw;
      }
    }
  }
}
