@use "../util" as *;

.user-message {
  background-color: $clr-red-light;
  color: $clr-red-dark;
  border-radius: $radius-small;
  min-width: max-content;
  max-width: max-content;
  font-size: 0.8rem;
  padding: 0.1rem 0.3rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 0.2rem));
  font-weight: 600;
  animation: messageAppear 0.3s forwards;
  .notch {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 7px solid $clr-red-light;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -0.25rem);
  }
}

@keyframes messageAppear {
  from {
    z-index: -10;
    visibility: hidden;
    opacity: 0;
  }
  to {
    z-index: 5;
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: $bp-phone) {
  .user-message {
    font-size: 0.7rem;
  }
}
