@use "../util" as *;

header.nav-header {
  @include flexCenter(row);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  .header-center {
    @include flexCenter(row);
    width: $pageWidth;
    nav {
      display: flex;
      align-items: center;
    }
    a {
      font-size: $text-large;
      color: $clr-text-dark;
      padding: 0.5rem 2rem;
      @include transition(opacity);
      &:nth-child(1),
      &:nth-child(3) {
        font-weight: 500;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .logo-mobile {
    display: none;
  }
  .menu-btn {
    font-size: $heading-medium;
    padding: 1rem;
    padding-right: 0;
    display: none;
  }
  .close-menu-btn {
    display: none;
  }
  .bcg-blur {
    display: none;
  }
}

.header-placeholder {
  width: 100%;
  height: 2rem;
}

@media (max-width: $bp-tablet) {
  header.nav-header {
    .logo-mobile,
    .menu-btn,
    .close-menu-btn {
      display: flex;
    }

    .close-menu-btn {
      font-size: $heading-large;
      padding: 1rem;
      position: absolute;
      top: 0;
      right: 0;
    }

    .header-center {
      justify-content: space-between;
      .logo {
        padding-left: 0;
      }
    }
    nav {
      position: fixed;
      background-color: white;
      width: clamp(270px, 100%, 500px);
      height: 100vh;
      left: 0;
      top: 0;
      flex-direction: column;
      justify-content: center;
      transform: translateX(-100%);
      @include transition(transform);
      z-index: 5;

      a {
        width: 100%;
        text-align: center;
        padding: 3rem !important;
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
        }
      }
      .logo {
        order: 1;
        width: max-content;
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem !important;
      }
    }
    .bcg-blur {
      display: flex;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100%;
      z-index: -5;
      opacity: 0;
      visibility: hidden;
      @include transition(opacity);
    }
    .show {
      nav {
        transform: translateX(0);
      }
      .bcg-blur {
        z-index: 2;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
